/**
 * @author https://vue-admin-beautiful.com （不想保留author可删除）
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from '@/router'
import store from '@/store'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { getUserInfo } from "@/api/index";
let progressBar = false;
VabProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})
router.beforeResolve(async (to, from, next) => {
  if (progressBar) VabProgress.start()
  let hasToken = localStorage.getItem('Token')
  // console.log(to.path, 'to.path')

  // next() //新增
  // return;//新增
  if (hasToken) {
    // if (to.path === '/login') {
    //   next({ path: '/' })
    //   if (progressBar) VabProgress.done()
    // } else {
    //   if (progressBar) VabProgress.done()
    //   next()
    // }
    if (to.path === '/sellingAssets') {
      let userInfo = await getUserInfo({})
      if (userInfo.data.is_auth == 0) {
        store.commit('changeIsLogin', true);
        next('/certification')
      } else {
        store.commit('changeIsLogin', true);
        next()
      }

    } else {
      store.commit('changeIsLogin', true);
      next()
    }



  } else {
    store.commit('changeIsLogin', false);
    if (to.path === '/home') {
      next()
      if (progressBar) VabProgress.done()
    } else {
      next('/home')
    }
  }
})
router.afterEach(() => {
  if (progressBar) VabProgress.done()
})
