/*
 * @Author: your name
 * @Date: 2021-11-11 18:15:16
 * @LastEditTime: 2021-11-11 18:25:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \template_page\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './config/permission'
Vue.config.productionTip = false;
// import "lib-flexible";
import Vant,{ Dialog, NoticeBar } from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
Vue.use(NoticeBar);
Vue.prototype.$dialog = Dialog;
import svgIcon from "@/components/svgIcon";

Vue.component("svgIcon", svgIcon);

import ElementUI from "element-ui"; // 引入element-ui
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI); // 这种方式引入了ElementUI中所有的组

import VueI18n from "vue-i18n";
import LangEn from "./assets/lang/en";
import LangCh from "./assets/lang/ch";
Vue.use(VueI18n);
let langType = "en";
if (
  localStorage.getItem("langType") &&
  localStorage.getItem("langType") != undefined
) {
  if (localStorage.getItem("langType") == "ch") {
    langType = "ch";
  } else if (localStorage.getItem("langType") == "en") {
    langType = "en";
  } else {
    langType = "en";
    localStorage.setItem("langType", "en");
  }
} else {
  localStorage.setItem("langType", "en");
}
console.log(langType, "langType");
const i18n = new VueI18n({
  locale: langType, // uni.getStorageSync('langType') ||
  messages: {
    ch: LangCh,
    en: LangEn,
  },
});

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import VueParticles from "vue-particles";
Vue.use(VueParticles);
Vue.use(VueAwesomeSwiper);
Vue.prototype._i18n = i18n;
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
// 引入字体样式
import "@/assets/font/font.css";

//引入svg图标
import "@/icons";
// 定义过滤器函数
Vue.filter('num1', function (val, fix = 0) {
  if(!val) return ""
  val=val+''
  let num2= val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num2
});
new Vue({

  router,
  store,
  render: (h) => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    if (document.body.clientWidth <= 1200) {
      setRem();
      window.addEventListener("resize", setRem); //浏览器窗口大小改变时调用rem换算方法
    } else {
      setRemPC();
      window.addEventListener("resize", setRemPC); //浏览器窗口大小改变时调用rem换算方法
    }
    // if (
    //   navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   )
    // ) {
    //   // window.location.href="https://pay.keynes.world/"
    //   //移动端使用
    //   setRem();
    //   window.addEventListener("resize", setRem); //浏览器窗口大小改变时调用rem换算方法
    // } else {
    //   //pc端使用
    //   setRemPC();
    //   window.addEventListener("resize", setRemPC); //浏览器窗口大小改变时调用rem换算方法
    // }
  }
}).$mount("#app");
// 手机端
function setRem() {
  // console.log("设置移动");
  // var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
  // var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  // var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  // document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
  var docEl = document.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
    };
  if (!document.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
  document.addEventListener("DOMContentLoaded", recalc, false);
}
//pc端
function setRemPC() {
  console.log("设置PC");
  var whdef = 100 / 1440; // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  console.log(rem, "resss");
  document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
}
