/**
 * @description 导出默认网路配置
 **/
const network = {
  // 默认的接口地址 如果是开发环境和生产环境走vab-mock-server，当然你也可以选择自己配置成需要的接口地址
  downLoadUrl:
    process.env.NODE_ENV === "development"
      ? "https://api.profitbotaix.ink/"
      : "https://api.profitbotaix.ink/",
  baseURL:
    process.env.NODE_ENV === "development"
      ? "/baseApi"
      : // "https://api.profitbotaix.ink"
        "https://api.profitbotaix.ink", //http://livemate.shanxinlife.com  http://prodlivemate.shanxinlife.com http://192.168.0.111:8083  /newApi  http://prodlabor.shanxinlife.com
  //配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
  imgUrl:
    process.env.NODE_ENV === "development"
      ? "/baseApi/api/upload/upload"
      : // ？  "https://api.profitbotaix.ink/api/upload/upload"
        "https://api.profitbotaix.ink/api/upload/upload", //http://192.168.0.111:8083/livemateapi/live/upload  http://labor.shanxinlife.com/laborapi/labor/upload http://prodlabor.shanxinlife.com/laborapi/labor/upload
  contentType: "application/json;charset=UTF-8",
  SDKAppID: 1600010089, //1600010089测试   1600002644  正式
  //消息框消失时间
  messageDuration: 3000,
  //最长请求时间
  requestTimeout: 100000,
  //操作正常code，支持String、Array、int多种类型
  successCode: [200, 0],
  //登录失效code
  invalidCode: 402,
  //无权限code
  noPermissionCode: 401,
};
module.exports = network;
