import request from "@/utils/request";

// 登录
export function login_pwd(data) {
  return request({
    url: `/api/login/login_pwd`,
    method: "post",
    data,
  });
}
// 注册
export function register(data) {
  return request({
    url: `/api/login/register`,
    method: "post",
    data,
  });
}
// 获取注册验证码
export function verify(data) {
  return request({
    url: `/api/login/verify`,
    method: "post",
    data,
  });
}
// 用户信息
export function getUserInfo(data) {
  return request({
    url: `/api/user/index`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 修改用户信息
export function update_info(data) {
  return request({
    url: `/api/user/update_info`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 首页数据
export function web_index(data) {
  return request({
    url: `/api/login/web_index`,
    method: "post",
    data,
  });
}

// 实名认证
export function set_auth(data) {
  return request({
    url: `/api/user/set_auth`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 人力银行分类
export function bank(data) {
  return request({
    url: `/api/user/bank`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 图片组
export function getImageList(data) {
  return request({
    url: `/api/user/getImageList`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 验证登录密码
export function check_pwd(data) {
  return request({
    url: `/api/user/check_pwd`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 验证登录密码提款密码
export function check_pwd_new(data) {
  return request({
    url: `/api/user/check_pwd_new`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 查看是否存在提款密码
export function check_tx_pwd_exists(data) {
  return request({
    url: `/api/user/check_tx_pwd_exists`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 设置提现密码
export function set_pwd(data) {
  return request({
    url: `/api/user/set_pwd`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 用户提现
export function withdraw(data) {
  return request({
    url: `/api/user/with`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 提现記錄
export function withdrawal_log(data) {
  return request({
    url: `/api/user/withdrawal_log`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 查询消息列表
export function system_notice(data) {
  return request({
    url: `/api/user/system_notice`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 获取方案列表
export function getFinanceCondition(data) {
  return request({
    url: `/api/trusteeship/getFinanceCondition`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}

// 获取方案列表
export function getProjectList(data) {
  return request({
    url: `/api/trusteeship/getProjectList`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 操作托管
export function trusteeship(data) {
  return request({
    url: `/api/trusteeship/trusteeship`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 操作托管
export function getOrderList(data) {
  return request({
    url: `/api/trusteeship/getOrderList`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取k线图数据
export function getKGraphData(data) {
  return request({
    url: `/api/coin/getKGraphData`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取币种列表
export function getCoinList(data) {
  return request({
    url: `/api/coin/getCoinList`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取交易周期
export function getTradingCycleInfo(data) {
  return request({
    url: `/api/coin/getTradingCycleInfo`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取交易市场配置
export function getConfig(data) {
  return request({
    url: `/api/coin/getConfig`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 下单CFDAOI
export function buyCoin(data) {
  return request({
    url: `/api/coin/buyCoin`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取用户交易所订单列表
export function getCoinOrderList(data) {
  return request({
    url: `/api/coin/getOrderList`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取币种价格
export function getCoinPrice(data) {
  return request({
    url: `/api/coin/getCoinPrice`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}
// 获取用户下单权限
export function getBuyPower(data) {
  return request({
    url: `/api/coin/getBuyPower`,
    method: "post",
    data: { ...data, token: localStorage.getItem("Token") },
  });
}