// routerM.js
export const routerPC = [
  {
    path: "/",
    component: () => import("@/views/pc/index.vue"),
    hidden: true,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/pc/home"),
        meta: {
          title: "Home",
          icon: "home",
          keepAlive: true,
        },
      },
      {
        path: "member",
        name: "member",
        component: () => import("@/views/pc/member"),
        meta: {
          title: "member",
          icon: "member",
          keepAlive: true,
        },
      },
      {
        path: "updateInfo",
        name: "updateInfo",
        component: () => import("@/views/pc/updateInfo"),
        meta: {
          title: "updateInfo",
          icon: "updateInfo",
          keepAlive: true,
        },
      },
      {
        path: "depositFunds",
        name: "depositFunds",
        component: () => import("@/views/pc/depositFunds"),
        meta: {
          title: "depositFunds",
          icon: "depositFunds",
          keepAlive: true,
        },
      },
      {
        path: "assetTransfer",
        name: "assetTransfer",
        component: () => import("@/views/pc/assetTransfer"),
        meta: {
          title: "assetTransfer",
          icon: "assetTransfer",
          keepAlive: true,
        },
      },
      {
        path: "sellingAssets",
        name: "sellingAssets",
        component: () => import("@/views/pc/sellingAssets"),
        meta: {
          title: "sellingAssets",
          icon: "sellingAssets",
          keepAlive: true,
        },
      },
      {
        path: "onlineService",
        name: "onlineService",
        component: () => import("@/views/pc/onlineService"),
        meta: {
          title: "onlineService",
          icon: "onlineService",
          keepAlive: true,
        },
      },
      {
        path: "typeInfo",
        name: "typeInfo",
        component: () => import("@/views/pc/typeInfo"),
        meta: {
          title: "typeInfo",
          icon: "typeInfo",
          keepAlive: true,
        },
      },
      {
        path: "typeList",
        name: "typeList",
        component: () => import("@/views/pc/typeList"),
        meta: {
          title: "typeList",
          icon: "typeList",
          keepAlive: true,
        },
      },
      {
        path: "trusteeship",
        name: "trusteeship",
        component: () => import("@/views/pc/trusteeship"),
        meta: {
          title: "trusteeship",
          icon: "trusteeship",
          keepAlive: true,
        },
      },
      {
        path: "trusteeshipOrder",
        name: "trusteeshipOrder",
        component: () => import("@/views/pc/trusteeshipOrder"),
        meta: {
          title: "trusteeshipOrder",
          icon: "trusteeshipOrder",
          keepAlive: true,
        },
      },
      {
        path: "coin",
        name: "coin",
        component: () => import("@/views/pc/coin"),
        meta: {
          title: "coin",
          icon: "coin",
          keepAlive: true,
        },
      },
      {
        path: "coinOrder",
        name: "coinOrder",
        component: () => import("@/views/pc/coinOrder"),
        meta: {
          title: "coinOrder",
          icon: "coinOrder",
          keepAlive: true,
        },
      },
      {
        path: "certification",
        name: "certification",
        component: () => import("@/views/pc/certification"),
        meta: {
          title: "certification",
          icon: "certification",
          keepAlive: true,
        },
      },
      {
        path: "withdrawRecord",
        name: "withdrawRecord",
        component: () => import("@/views/pc/withdrawRecord"),
        meta: {
          title: "withdrawRecord",
          icon: "withdrawRecord",
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/deal",
    name: "deal",
    component: () => import("@/views/pc/deal"),
    meta: {
      title: "deal",
      icon: "deal",
      keepAlive: true,
    },
  },
  {
    path: "/exchange",
    name: "exchange",
    component: () => import("@/views/pc/exchange"),
    meta: {
      title: "exchange",
      icon: "exchange",
      keepAlive: true,
    },
  },
  // {
  //   path: "/test",
  //   component: () => import("@/views/pc/test.vue"),
  // },
];
