module.exports = {
  header: {
    text1: "关于我们",
    text2: "实体卡",
    text3:'联系我们',
    text4:'开始使用'
  },
  footer: {
    text1: "简体中文",
    
  },
  home: {
    text1: "KNS生态",
    
  },
  
};
