import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import {
  baseURL
} from '@/config/net.config.js'
let loadingInstance

/**
 * @author https://vue-admin-beautiful.com （不想保留author可删除）
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  switch (code) {
    case invalidCode:
      Vue.prototype.$baseMessage(msg || `后端接口${code}异常`, 'error')
      store.dispatch('user/resetAccessToken').catch(() => { })
      if (loginInterception) {
        location.reload()
      }
      break
    case noPermissionCode:
      router.push({ path: '/401' }).catch(() => { })
      break
    default:
      Vue.prototype.$baseMessage(msg || `后端接口${code}异常`, 'error')
      break
  }
}

const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',//'application/json;charset=UTF-8'
  },
})

instance.interceptors.request.use(
  (config) => {
    // console.log(config, 'config1111')
    // if (localStorage.getItem('Token')) {
    //   config.headers['Token'] = localStorage.getItem('Token')
    // }
    if (config.contentType) {
      config.headers['Content-Type'] = config.contentType
    }
    //这里会过滤所有为空、0、false的key，如果不需要请自行注释
    // if (config.data)
    //   config.data = Vue.prototype.$baseLodash.pickBy(
    //     config.data,
    //     Vue.prototype.$baseLodash.identity
    //   )
    if (
      config.data &&
      config.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=UTF-8'
    )
      if (debounce.some((item) => config.url.includes(item)))
        // config.data = qs.stringify(config.data)
        loadingInstance = Vue.prototype.$baseLoading()
    // console.log(config, 'fonfigssss')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // console.log(response, 'responseresponseresponseresponseresponse')
    if (loadingInstance) loadingInstance.close()
    //code 200 成功 
    // console.log(response.data,'response.data')
    if (response.data.code == 401) {
      localStorage.clear('Token')
      localStorage.clear('uid')
      localStorage.clear('userInfo')
      store.commit('changeIsLogin', false);
      router.push('/')
      return Promise.reject(response)
    } else {
      return response.data
    }
  },
  (error) => {
    if (loadingInstance) loadingInstance.close()
    const { response, message } = error
    if (error.response && error.response.data) {
      const { status, data } = response
      handleCode(status, data.msg || message)
      return Promise.reject(error)
    } else {
      let { message } = error
      if (message === 'Network Error') {
        message = '后端接口连接异常'
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
      }
      Vue.prototype.$baseMessage(message || `后端接口未知异常`, 'error')
      return Promise.reject(error)
    }
  }
)

export default instance
