<template>
    <svg :class="svgClass" aria-hidden="true">
        <use :xlink:href="getIconName"></use>
    </svg>
</template>
  
<script>
export default {
    name: 'svg-icon',
    props: {
        icon: {
            type: String,
            require: true
        },
        className: {
            type: String,
            default: ''
        }
    },
    computed: {
        getIconName() {
            // console.log("icon",this.icon)
            return `#icon-${this.icon}`
        },
        svgClass() {
            if (this.className) {
                return 'svg-icon ' + this.className
            } else {
                return 'svg-icon'
            }
        }
    }
}
</script>
<style scoped>
.svg-icon {
    /* 默认样式设置 默认宽高1em*/
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    /* 当前颜色 */
    fill: currentColor;
    overflow: hidden;
}
</style>
  
  