module.exports = {
  header: {
    text1: "About Us",
    text2: "Physical Card",
    text3: "Contact Us",
    text4: "Get Started",
  },
  footer: {
    text1: "Chinese",
  },
  home: {
    text1: "KNS Ecosystem",
  },
};
