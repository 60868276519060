import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDay: false,
    isLogin:false
  },
  mutations: {
    // 切换主题模式
    toggleDayNight(state, data) {
      state.isDay = data;
    },
    changeIsLogin(state,data){
      state.isLogin = data;
    }
  },
  actions: {},
  modules: {},
});
